// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#scrPageTop');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight,position;
  $('a[href^="#"]:not(.notScroll)').each(function () {
    var pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      var id = this.href.split('#')[1];
      $(this).on('click', function () {
        headerHeight = $('#header').outerHeight(true);
        position = $('#' + id).offset().top - headerHeight;
        $('body,html').animate({
          scrollTop: position
        }, 1000, 'easeInOutQuart');
        return false;
      });
    }
  });

  
  const url = $(location).attr('href');
  if (url.indexOf("#") != -1) {
    const id = url.split("#");
    const $target = $('#' + id[id.length - 1]);
    setTimeout(function(){
      headerHeight = $('#header').outerHeight(true);
      position = $target.offset().top - headerHeight;
      $('body,html').animate({
        scrollTop: position
      }, 1000, 'easeInOutQuart');
      return false;
    },500);
  }
  if (url.indexOf("?id=") != -1) {
    const id = url.split("?id=");
    const $target = $('#' + id[id.length - 1]);
    setTimeout(function(){
      if ($target.length) {
        $('#header').removeClass('changeColor');
        let headerHeight = $('#header').outerHeight(true);
        if ($('#subNav').length) {
          let subNavHeight = $('#subNav').outerHeight(true);
          let pos = $target.offset().top - headerHeight - subNavHeight;
          $("html, body").animate({
            scrollTop: pos
          }, 1000, 'easeInOutCubic');
        } else {
          let pos = $target.offset().top - headerHeight;
          $("html, body").animate({
            scrollTop: pos
          }, 1000, 'easeInOutCubic');
        }
      }
    },200);
  }
}