import gsap from 'gsap';
import {
  ScrollTrigger,
  ScrollToPlugin,
  CustomEase
} from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase);
let setTimer = false;

export function worksFunc() {
  let worksTop = $('#worksTop').length;

  if (worksTop) {
    document.querySelectorAll(".worksCard").forEach((el) => {
      ScrollTrigger.create({
        invalidateOnRefresh: true,
        trigger: el,
        start: 'top 55%',
        end: "bottom 45%",
        toggleClass: {targets: el, className: "active"},
      });
    });

    
    let accordionBtn = $('#worksMoreBtn')
    let accordionInner = $('#worksMoreArea')

    accordionBtn.on('click',function(){
      if (accordionInner.not(':animated').length >= 1) {
        accordionInner.stop().slideToggle();
        accordionBtn.hide();
      }
    });
      
    let worksCardNum = $('#worksMoreArea .worksCard').length;
    console.log(worksCardNum);
    if (worksCardNum != 0) {
      accordionBtn.show();
    };
  }
}