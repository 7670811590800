const contactForm = $('#contactForm').length;
const contactFormEn = $('#contactFormEn').length;

export function contactFunc() {
  if (contactForm) {
    let selectIndex = '';

    // 必須項目名
    const type = $("[name='type']");
    const companyName = $("[name='companyName']");
    const myName = $("[name='myName']");
    const email = $("[name='email']");
    const telNumber = $("[name='telNumber']");
    const content = $("[name='content']");;
    /**************/
    const agreeCheck = $("[name='agreeCheck']");

    // 必須項目定義
    const onlineRequired = [type, companyName, myName, email, telNumber, content];

    // 必須項目設定
    let requiredField = [];
    const requiredFieldSet = function () {
      requiredField = onlineRequired;
      for (let i = 0; i < requiredField.length; i++) {
        let requiredFieldType = requiredField[i].attr('type');
        if (requiredFieldType == 'radio' || requiredFieldType == 'checkbox') {
          requiredField[i].change(function () {
            fieldCheck(requiredField[i]);
          });
        } else {
          requiredField[i].blur(function () {
            fieldCheck(requiredField[i]);
          });
        }
      }
    }

    // リロード時実行
    requiredFieldSet();

    const agreeCheckTxt = '「プライバシーポリシーに同意する」にチェックをしてください';
    const emailRequiredTxt = '正しいメールアドレスを入力してください';
    const emailDuplication = '上記フォームで入力したメールアドレスと異なります';
    const telRequiredTxt = '10桁〜12桁の数字を入力してください';
    const ageRequiredTxt = '半角英数字を入力してください';

    let errorTxt = '';
    const fieldCheck = function (checkField, all = false) {
      let checkFieldValue = checkField.val();
      let checkFieldType = checkField.attr('type');
      //let checkFieldTag = checkField[0].tagName;
      let checkFieldName = checkField.attr('name');
      let checkFieldLabel = $('[for= ' + checkFieldName + ']');
      let checkFieldLabelTxt = checkFieldLabel.text().replace("　※必須", "");
      if (checkFieldType == 'radio') {
        errorTxt = checkFieldLabelTxt + 'は選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      } else {
        errorTxt = checkFieldLabelTxt + 'は入力必須です';
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } 
        else if (checkField == myName && checkFieldValue.length >= 51) {
          errorTxt = checkFieldLabelTxt + 'は入力必須です';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else if (checkFieldType == 'email' && !checkFieldValue.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          checkField.addClass('error').next('.errorTxt').text(emailRequiredTxt);
        } 
        else if (checkField == email && checkFieldValue.length >= 51) {
          errorTxt = checkFieldLabelTxt + 'は入力必須です';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else if (checkFieldType == 'tel' && !checkFieldValue.match('^[0-9]{10,12}$')) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        }
        else if (checkField == telNumber && checkFieldValue.length >= 21) {
          errorTxt = checkFieldLabelTxt + 'は入力必須です';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
    };

    // content.blur(function () {
    //   if (content.val().length >= 501) {
    //     errorTxt = '500文字以内で入力してください';
    //     content.addClass('error').next('.errorTxt').text(errorTxt);
    //   } else {
    //     content.removeClass('error').next('.errorTxt').text('');
    //   }
    // });
    
    let sendBtn = $('#sendBtn');
    // 各項目をサブミット処理でチェック（個人、法人フォーム）
    $('form').submit(function (e) {
      // 初期設定
      let set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      // 各項目をチェック（個人フォーム用）
      for (let i = 0; i < requiredField.length; i++) {
        fieldCheck(requiredField[i]);
      }

      // エラー箇所があればその場所までスクロール
      let offsetTxt, headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $('.error').offset().top;
        headerHeight = $('#header').outerHeight();
        $('html,body').animate({
          scrollTop: offsetTxt - headerHeight
        }, 500);
        return false;
      }

      // エラーがなければ二重送信禁止
      sendBtn.prop('disabled', 'true');
      setTimeout(function () {
        sendBtn.prop('disabled', '');
      }, 1000);
      return true;
    });

    // ラジオボタンチェック時入力欄有効可
    const radioBtn = document.querySelectorAll('.radioBtn');
    const radioField = document.querySelectorAll('.radioField');
    for (let i = 0; i < radioBtn.length; i++) {
      if (radioBtn[i].checked) {
        radioField[i].disabled = false;
      } else {
        radioField[i].disabled = true;
      }
    }
    $("input[type='radio']").on('click', function () {
      for (let i = 0; i < radioBtn.length; i++) {
        if (radioBtn[i].checked) {
          radioField[i].disabled = false;
        } else {
          radioField[i].disabled = true;
          radioField[i].value = '';
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn = document.querySelectorAll('.checkBtn');
    const checkField = document.querySelectorAll('.checkField');
    for (let i = 0; i < checkBtn.length; i++) {
      if (checkBtn[i].checked) {
        checkField[i].disabled = true;
        checkField[i].value = '';
      } else {
        checkField[i].disabled = false;
      }
    }
    $("input[type='checkbox']").on('click', function () {
      for (let i = 0; i < checkBtn.length; i++) {
        if (checkBtn[i].checked) {
          checkField[i].disabled = true;
          checkField[i].value = '';
        } else {
          checkField[i].disabled = false;
        }
      }
    });

    //コンサルについてのリンクボタンから来た場合
    var param = location.search;
    if(param == "?type=consultant"){
      $("[value='コンサルティングについて']").prop('checked', true);
      $("[value='Consulting']").prop('checked', true);
    } 
  }
  if (contactFormEn) {
    let selectIndex = '';

    // 必須項目名
    const type = $("[name='type']");
    const companyName = $("[name='companyName']");
    const myName = $("[name='myName']");
    const email = $("[name='email']");
    const telNumber = $("[name='telNumber']");
    const content = $("[name='content']");;
    /**************/
    const agreeCheck = $("[name='agreeCheck']");

    // 必須項目定義
    const onlineRequired = [type, companyName, myName, email, telNumber, content];

    // 必須項目設定
    let requiredField = [];
    const requiredFieldSet = function () {
      requiredField = onlineRequired;
      for (let i = 0; i < requiredField.length; i++) {
        let requiredFieldType = requiredField[i].attr('type');
        if (requiredFieldType == 'radio' || requiredFieldType == 'checkbox') {
          requiredField[i].change(function () {
            fieldCheck(requiredField[i]);
          });
        } else {
          requiredField[i].blur(function () {
            fieldCheck(requiredField[i]);
          });
        }
      }
    }

    // リロード時実行
    requiredFieldSet();

    const agreeCheckTxt = '「プライバシーポリシーに同意する」にチェックをしてください';
    const emailRequiredTxt = 'Please enter a valid e-mail address';
    const emailDuplication = '上記フォームで入力したメールアドレスと異なります';
    const telRequiredTxt = 'Please enter a 10-to-12-digit number';
    const ageRequiredTxt = '半角英数字を入力してください';
    const myNameRequiredTxt = 'Full name is required';
    const detailsRequiredTxt = 'The inquiry content is required';

    let errorTxt = '';
    const fieldCheck = function (checkField, all = false) {
      let checkFieldValue = checkField.val();
      let checkFieldType = checkField.attr('type');
      //let checkFieldTag = checkField[0].tagName;
      let checkFieldName = checkField.attr('name');
      let checkFieldLabel = $('[for= ' + checkFieldName + ']');
      let checkFieldLabelTxt = checkFieldLabel.text().replace("　※必須", "");
      if (checkFieldType == 'radio') {
        errorTxt = checkFieldLabelTxt + ' must be selected';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      }
      else if (checkFieldName == 'myName') {
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(myNameRequiredTxt);
        } else if (checkField == myName && checkFieldValue.length >= 51) {
          errorTxt = 'Full name is required';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
      else if (checkFieldName == 'content') {
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(detailsRequiredTxt);
        } else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
      else {
        errorTxt = checkFieldLabelTxt + ' is required';
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else if (checkFieldType == 'email' && !checkFieldValue.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          checkField.addClass('error').next('.errorTxt').text(emailRequiredTxt);
        } 
        else if (checkField == email && checkFieldValue.length >= 51) {
          errorTxt = checkFieldLabelTxt + ' is required';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else if (checkFieldType == 'tel' && !checkFieldValue.match('^[0-9]{10,12}$')) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        }
        else if (checkField == telNumber && checkFieldValue.length >= 21) {
          errorTxt = checkFieldLabelTxt + ' is required';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
    };

    // content.blur(function () {
    //   if (content.val().length >= 501) {
    //     errorTxt = '500文字以内で入力してください';
    //     content.addClass('error').next('.errorTxt').text(errorTxt);
    //   } else {
    //     content.removeClass('error').next('.errorTxt').text('');
    //   }
    // });
    
    let sendBtn = $('#sendBtn');
    // 各項目をサブミット処理でチェック（個人、法人フォーム）
    $('form').submit(function (e) {
      // 初期設定
      let set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      // 各項目をチェック（個人フォーム用）
      for (let i = 0; i < requiredField.length; i++) {
        fieldCheck(requiredField[i]);
      }

      // エラー箇所があればその場所までスクロール
      let offsetTxt, headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $('.error').offset().top;
        headerHeight = $('#header').outerHeight();
        $('html,body').animate({
          scrollTop: offsetTxt - headerHeight
        }, 500);
        return false;
      }

      // エラーがなければ二重送信禁止
      sendBtn.prop('disabled', 'true');
      setTimeout(function () {
        sendBtn.prop('disabled', '');
      }, 1000);
      return true;
    });

    // ラジオボタンチェック時入力欄有効可
    const radioBtn = document.querySelectorAll('.radioBtn');
    const radioField = document.querySelectorAll('.radioField');
    for (let i = 0; i < radioBtn.length; i++) {
      if (radioBtn[i].checked) {
        radioField[i].disabled = false;
      } else {
        radioField[i].disabled = true;
      }
    }
    $("input[type='radio']").on('click', function () {
      for (let i = 0; i < radioBtn.length; i++) {
        if (radioBtn[i].checked) {
          radioField[i].disabled = false;
        } else {
          radioField[i].disabled = true;
          radioField[i].value = '';
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn = document.querySelectorAll('.checkBtn');
    const checkField = document.querySelectorAll('.checkField');
    for (let i = 0; i < checkBtn.length; i++) {
      if (checkBtn[i].checked) {
        checkField[i].disabled = true;
        checkField[i].value = '';
      } else {
        checkField[i].disabled = false;
      }
    }
    $("input[type='checkbox']").on('click', function () {
      for (let i = 0; i < checkBtn.length; i++) {
        if (checkBtn[i].checked) {
          checkField[i].disabled = true;
          checkField[i].value = '';
        } else {
          checkField[i].disabled = false;
        }
      }
    });

    //コンサルについてのリンクボタンから来た場合
    var param = location.search;
    if(param == "?type=consultant"){
      $("[value='コンサルティングについて']").prop('checked', true);
      $("[value='Consulting']").prop('checked', true);
    } 
  }
}
